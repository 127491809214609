import React, { useCallback, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button, CircularProgress } from '@mui/material';
import { ApproveType, MAX_UINT_AMOUNT } from '@yldr/contract-helpers';

import { useRootStore } from '../../../store/root';
import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';
import { checkRequiresApproval } from '../../../components/transactions/utils';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { TokenIcon } from '../../../components/primitives/TokenIcon';
import { useModalContext } from '../../../hooks/useModal';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { valueWithDecimals } from '../../../utils/valueWithDecimals';
import { useWalletBalances } from '../../../hooks/app-data-provider/useWalletBalances';

export interface ITokensApprovalProps {
  marketId?: number;
  tokenA?: ComputedReserveData;
  tokenB?: ComputedReserveData;
  approvedAmountA?: ApproveType;
  approvedAmountB?: ApproveType;
  amountToSupplyA: BigNumber;
  amountToSupplyB: BigNumber;
  setApprovedAmountA: React.Dispatch<React.SetStateAction<ApproveType | undefined>>;
  setApprovedAmountB: React.Dispatch<React.SetStateAction<ApproveType | undefined>>;
  requiresApprovalA: boolean;
  requiresApprovalB: boolean
  setRequiresApprovalA: React.Dispatch<React.SetStateAction<boolean>>;
  setRequiresApprovalB: React.Dispatch<React.SetStateAction<boolean>>;
  isIncreaseLiquidity?: boolean;
}

export interface ITokenApproveWrapperProps {
  marketId?: number;
  token?: ComputedReserveData;
  approvedAmount?: ApproveType;
  amountToSupply: BigNumber;
  setApprovedAmount: React.Dispatch<React.SetStateAction<ApproveType | undefined>>
  requiresApproval: boolean
  setRequiresApproval: React.Dispatch<React.SetStateAction<boolean>>;
  isIncreaseLiquidity?: boolean;
}

export const TokensApproval = ({
  marketId,
  tokenA,
  tokenB,
  approvedAmountA,
  approvedAmountB,
  amountToSupplyA,
  amountToSupplyB,
  setApprovedAmountA,
  setApprovedAmountB,
  requiresApprovalA,
  requiresApprovalB,
  setRequiresApprovalA,
  setRequiresApprovalB,
  isIncreaseLiquidity,
}: ITokensApprovalProps) => (
  <Box sx={{ mb: 6 }}>
    <TokenApprove
      marketId={marketId}
      token={tokenA}
      approvedAmount={approvedAmountA}
      amountToSupply={amountToSupplyA}
      setApprovedAmount={setApprovedAmountA}
      requiresApproval={requiresApprovalA}
      setRequiresApproval={setRequiresApprovalA}
      isIncreaseLiquidity={isIncreaseLiquidity}
    />
    <TokenApprove
      marketId={marketId}
      token={tokenB}
      approvedAmount={approvedAmountB}
      amountToSupply={amountToSupplyB}
      setApprovedAmount={setApprovedAmountB}
      requiresApproval={requiresApprovalB}
      setRequiresApproval={setRequiresApprovalB}
      isIncreaseLiquidity={isIncreaseLiquidity}
    />
  </Box>
);

export const TokenApprove = ({
  marketId,
  token,
  approvedAmount,
  amountToSupply,
  setApprovedAmount,
  requiresApproval,
  setRequiresApproval,
  isIncreaseLiquidity,
}: ITokenApproveWrapperProps) => {
  const { sendTx } = useWeb3Context();
  const { setTxError, setMainTxState, mainTxState } = useModalContext();

  const [
    getPoolApprovedAmount,
    generatePositionApproval,
    estimateGasLimit,
    currentMarketData,
  ] = useRootStore((store) => [
    store.getPoolApprovedAmount,
    store.generatePositionApproval,
    store.estimateGasLimit,
    store.currentMarketData,
  ]);

  const { walletBalances } = useWalletBalances(currentMarketData);

  const fetchApprovedAmount = useCallback(
    async (forceApprovalCheck?: boolean) => {
      if (!token) return;

      if (marketId && (!approvedAmount || forceApprovalCheck)) {
        const approvedAmount = await getPoolApprovedAmount({ marketId, token: token?.underlyingAsset, isIncreaseLiquidity });
        setApprovedAmount(approvedAmount);
      }

      if (approvedAmount) {
        const fetchedRequiresApproval = checkRequiresApproval({
          approvedAmount: approvedAmount.amount,
          amount: valueWithDecimals(amountToSupply, token.decimals).toString(),
          signedAmount: '0',
        });
        setRequiresApproval(fetchedRequiresApproval);
      }
    },
    [approvedAmount, amountToSupply.toString(), getPoolApprovedAmount]
  );

  const approval = async () => {
    try {
      if (requiresApproval && approvedAmount && token?.underlyingAsset) {
        setMainTxState({ ...mainTxState, loading: true, approvingToken: token.symbol });
        const walletBalance = walletBalances[token?.underlyingAsset.toLowerCase()].amountWithDecimals;
        const approveTxData = await estimateGasLimit(
          generatePositionApproval({
            ...approvedAmount,
            amount: walletBalance || MAX_UINT_AMOUNT,
          })
        );
        const response = await sendTx(approveTxData);
        await response.wait(1);
        await fetchApprovedAmount(true);
        setMainTxState({ loading: false });
      }
    } catch (error) {
      const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
      setTxError(parsedError);
      setMainTxState({ loading: false });
    }
  };

  const isLoading = mainTxState.loading && mainTxState.approvingToken === token?.symbol;

  useEffect(() => {
    fetchApprovedAmount();
  }, [fetchApprovedAmount, token?.symbol, amountToSupply.toString()]);

  return token ? (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      py: 2,
      pl: 4,
      pr: 2,
      border: '1px solid rgba(255, 255, 255, 0.06)',
      ['&:first-child']: {
        borderRadius: '8px 8px 2px 2px',
        mb: 1,
      },
      ['&:last-child']: {
        borderRadius: '2px 2px 8px 8px',
      }
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TokenIcon symbol={token.iconSymbol} fontSize="small" />
        {token.symbol}
      </Box>
      <Button
        variant="contained"
        disabled={!requiresApproval || isLoading}
        onClick={approval}
        startIcon={isLoading && <CircularProgress size="16px" />}
      >
        Approve
      </Button>
    </Box>
  ) : null;
};
