import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import React, { ReactNode } from 'react'
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import { Box, styled, Theme, SxProps, Typography } from '@mui/material';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { Pair } from 'yaml/types';
import { isSupportedChain } from '../../LiquidityChartRangeInput/utils/isSupportedChain';
import { Input as NumericalInput } from '../NumericalInput'
import { TokenIcon } from '../../../components/primitives/TokenIcon';
import { useCurrencyBalance } from '../../LiquidityChartRangeInput/hooks/useCurrencyBalance';
import BigNumber from 'bignumber.js';

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  font-size: 20px !important;
  text-align: left;
`

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: ReactNode;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  fiatValue?: string;
  id: string;
  showCommonBases?: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  disableErrors?: boolean;
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode;
  locked?: boolean;
  loading?: boolean;
  iconSymbol?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const CurrencyInputPanel = ({
  value,
  onUserInput,
  onMax,
  currency,
  fiatValue,
  loading = false,
  disableErrors,
  iconSymbol,
  disabled,
  sx,
}: CurrencyInputPanelProps) => {
  const { chainId, currentAccount } = useWeb3Context();
  const selectedCurrencyBalance = useCurrencyBalance(currentAccount, currency ?? undefined);

  const chainAllowed = isSupportedChain(chainId);
  const hasAmount = BigNumber(value || 0).isZero() ||
    BigNumber(value).isLessThanOrEqualTo(selectedCurrencyBalance?.toFixed() || 0);

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        py: 3,
        px: 4,
        borderRadius: 2,
        border: hasAmount ? '1px solid rgba(255, 255, 255, 0.06)' : '1px solid #FF0548',
        backgroundColor: '#26262B',
        ...sx,
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <StyledNumericalInput
            className="token-amount-input"
            value={value}
            onUserInput={onUserInput}
            disabled={!chainAllowed || disabled}
            $loading={loading}
          />

          {currency?.symbol && iconSymbol && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <TokenIcon symbol={iconSymbol} fontSize="medium" />
              <Typography variant="main19">
                {currency.symbol}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Typography variant="main13" color="text.secondary">
            ${fiatValue || 0}
          </Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
          }}>
            <Typography variant="main13" color="text.secondary">
              Wallet balance: {selectedCurrencyBalance?.toFixed(2)}
            </Typography>
            {onMax && !disabled && (
              <Typography
                sx={{ fontSize: '11px', fontWeight: '600', cursor: 'pointer' }}
                onClick={onMax}
              >
                MAX
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {!hasAmount && !disableErrors && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          pt: 1,
        }}>
          <ReportRoundedIcon color="error" sx={{ width: '18px', height: '18px' }} />
          <Typography variant="main13" sx={{ color: '#FCB6C8' }}>
            Insufficient funds to supply
          </Typography>
        </Box>
      )}
    </Box>
  )
}
