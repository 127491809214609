import React, { useEffect, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { Box, Typography } from '@mui/material';
import { Currency, CurrencyAmount } from '@uniswap/sdk-core';
import { Trans } from '@lingui/macro';

import { CurrencyInputPanel } from '../../../components-yldr/components/CurrencyInputPanel';
import { useModalContext } from '../../../hooks/useModal';
import { findTokenInReserves } from '../../../utils/findTokenInReserves';
import { useCurrency } from '../../../components-yldr/LiquidityChartRangeInput/hooks/useCurrency';
import { useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { useCurrencyBalances } from '../../../components-yldr/LiquidityChartRangeInput/hooks/useCurrencyBalance';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { Field } from '../../../components-yldr/LiquidityChartRangeInput/types';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
// import { SelectSlippage } from '../../../components-yldr/SelectSlippage';
// import { DEFAULT_SLIPPAGE } from '../../../ui-config/networksConfig';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import { SLIPPAGE_WARNING } from '../constants';
import { AddLiquidityActions } from './AddLiquidityActions';
import { calculateValue } from './helpers/calculateValue';

export const AddLiquidityModalContent = React.memo(
  ({ enrichedPosition, isWrongNetwork }: PositionModalWrapperProps) => {
    // const [slippage, setSlippage] = useState(DEFAULT_SLIPPAGE.toString());
    const [baseField, setBaseField] = useState(Field.CURRENCY_A);
    const [valueA, setValueA] = useState('');
    const [valueB, setValueB] = useState('');
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();
    const { reserves } = useAppDataContext();
    const { currentAccount } = useWeb3Context();

    const tokenA = findTokenInReserves(reserves, enrichedPosition.token0.underlyingAsset);
    const tokenB = findTokenInReserves(reserves, enrichedPosition.token1.underlyingAsset);

    const baseCurrency = useCurrency(tokenA);
    const quoteCurrency = useCurrency(tokenB);

    const totalPositionLiquidity = BigNumber(enrichedPosition.marketReferenceCurrencyValue)
      .plus(BigNumber(valueA || 0).multipliedBy(enrichedPosition.token0.priceInMarketReferenceCurrency))
      .plus(BigNumber(valueB || 0).multipliedBy(enrichedPosition.token1.priceInMarketReferenceCurrency))
      .toFixed();

    const featureHealthFactor = BigNumber(totalPositionLiquidity)
      .multipliedBy(enrichedPosition.liquidationThreshold)
      .div(enrichedPosition.marketReferenceCurrencyDebt || 0)
      .toFixed();

    const amountToIncreaseA = BigNumber(valueA || 0)
      .multipliedBy(BigNumber(10).pow(enrichedPosition.token0.decimals || 0));
    const amountToIncreaseB = BigNumber(valueB || 0)
      .multipliedBy(BigNumber(10).pow(enrichedPosition.token1.decimals || 0));

    const deleveragePositionActionsProps = {
      enrichedPosition,
      tokenA,
      tokenB,
      amountToIncreaseA,
      amountToIncreaseB,
      isWrongNetwork,
    }

    const balances = useCurrencyBalances(
      currentAccount,
      useMemo(() => [baseCurrency, quoteCurrency], [baseCurrency?.symbol, quoteCurrency?.symbol])
    );

    const currencyBalances: { [field in Field]?: CurrencyAmount<Currency> } = {
      [Field.CURRENCY_A]: balances[0],
      [Field.CURRENCY_B]: balances[1],
    };

    const usdcValues = {
      [Field.CURRENCY_A]: BigNumber(valueA).multipliedBy(enrichedPosition.token0.priceInMarketReferenceCurrency),
      [Field.CURRENCY_B]: BigNumber(valueB).multipliedBy(enrichedPosition.token1.priceInMarketReferenceCurrency),
    };

    const usdcValueCurrencyA = usdcValues[Field.CURRENCY_A];
    const usdcValueCurrencyB = usdcValues[Field.CURRENCY_B];

    const onFieldAInput = (value: string) => {
      setBaseField(Field.CURRENCY_A);
      setValueA(value);
      setValueB(calculateValue(enrichedPosition, value, Field.CURRENCY_B) || '');
    };

    const onFieldBInput = (value: string) => {
      setBaseField(Field.CURRENCY_B);
      setValueB(value);
      setValueA(calculateValue(enrichedPosition, value, Field.CURRENCY_A) || '');
    };

    useEffect(() => {
      if (baseField === Field.CURRENCY_A) {
        setValueB(calculateValue(enrichedPosition, valueA, Field.CURRENCY_B) || '');
      }
      if (baseField === Field.CURRENCY_B) {
        setValueA(calculateValue(enrichedPosition, valueB, Field.CURRENCY_A) || '');
      }
    }, [enrichedPosition.poolPrice]);

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully compounded</Trans>}
            prefix="$"
            amount={mainTxState.value}
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalContent sx={{ pt: 4 }}>
          <Typography variant="secondary14" color="text.tertiary">
            Liquidity to add
          </Typography>
          <Box sx={{ mt: 2 }}>
            <CurrencyInputPanel
              id="add-liquidity-input-tokena"
              value={valueA}
              iconSymbol={enrichedPosition.token0?.iconSymbol}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(currencyBalances[Field.CURRENCY_A]?.toFixed() ?? '')
              }}
              showMaxButton={Boolean(balances[0])}
              currency={baseCurrency}
              fiatValue={usdcValueCurrencyA.isFinite() ? usdcValueCurrencyA?.toFixed(2) : undefined}
              sx={{ borderRadius: '8px 8px 0 0' }}
              showCommonBases
              disableErrors
              disabled={enrichedPosition.poolPrice.isGreaterThanOrEqualTo(enrichedPosition.upperPosition)}
            />

            <CurrencyInputPanel
              id="add-liquidity-input-tokenb"
              value={valueB}
              iconSymbol={enrichedPosition.token1?.iconSymbol}
              onUserInput={onFieldBInput}
              onMax={() => {
                onFieldBInput(currencyBalances[Field.CURRENCY_B]?.toFixed() ?? '')
              }}
              showMaxButton={Boolean(balances[1])}
              fiatValue={usdcValueCurrencyB.isFinite() ? usdcValueCurrencyB?.toFixed(2) : undefined}
              currency={quoteCurrency}
              sx={{ borderRadius: '0 0 8px 8px' }}
              showCommonBases
              disableErrors
              disabled={enrichedPosition.poolPrice.isLessThanOrEqualTo(enrichedPosition.lowerPosition)}
            />
          </Box>

          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            // bottomSlot={(
            //   <SelectSlippage
            //     defaultOptions={['0.5', '1']}
            //     value={slippage}
            //     onChange={setSlippage}
            //   />
            // )}
          >
            <DetailsNumberLine
              description="Total position liquidity"
              value={totalPositionLiquidity}
              numberPrefix='$'
            />
            <DetailsNumberLine
              description="Health factor"
              value={enrichedPosition.healthFactor}
              futureValue={featureHealthFactor}
              visibleDecimals={2}
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}
        </ScrollModalContent>

        <ScrollModalFooter>
          <AddLiquidityActions {...deleveragePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
